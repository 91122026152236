.main {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.removeBut {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px !important;
}
.smallRemove {
    font-size: 12px !important;
}
.actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}