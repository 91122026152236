.main {
    /* box-shadow: 2px -2px 10px -3px #FF5500; */
    border-right: 1px solid rgba(255, 85, 0, .3);
    height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    border-radius: 0;
}
.main button {
    text-transform: inherit !important;
    height: 60px !important;
    border-radius: 0 !important;
    color: white !important;
    /* border-bottom: 1px solid rgba(255, 85, 0, .3)!important; */
}
.linksBut {
    padding: 0 !important;
}
.main button:hover {
    background-color: rgba(255, 85, 0, .3) !important;
}
.main a {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 18px;
    color: white;
    font-family: 'helvetica-ce-bold';
    padding: 8px;
    box-sizing: border-box;
}
.main p {
    color: white;
    font-family: 'helvetica-ce-bold';
    font-size: 24px;
    display: inline;
    position: relative;
    top: -1px;
}
.logout {
    display: flex;
    align-items: center;
    gap: 8px !important;
    width: 100% !important;
    height: 100% !important;
    text-decoration: none !important;
    font-size: 18px !important;
    color: white !important;
    font-family: 'helvetica-ce-bold' !important;
    padding-left: 8px !important;
}
.main svg {
    width: 25px;
}
.main p {
    width: 25px;
}
.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 85, 0, .3);
}
.active {
    background-color: rgba(255, 85, 0, .5);
}