.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    box-sizing: border-box;
    color: white;
    /* box-shadow: 0px 2px 10px -3px #FF5500; */
    width: 100%;
}
.main h2 {
    font-family: 'helvetica-ce-bold';
}