.main {
    color: white !important;
    /* text-transform: inherit !important; */
    background-color: #FF5500 !important;
    font-family: 'helvetica-ce-bold' !important;
    font-size: 16px !important;
    border-radius: 0 !important;
}
.main:hover {
    background-color: #e45006 !important;
}