.main {
    font-family: "Helvetica";
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.main p {
    font-size: 20px;
    font-weight: bold;
}