.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: grab;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
}
.main img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.main button {
    position: absolute;
    opacity: 0;
    transition-duration: .3s !important;
    z-index: 2;
    background-color: rgba(128, 128, 128, .5) !important;
    color: white !important;
}
.main:hover button{
    opacity: .6;
}
.main button:hover {
    opacity: 1;
}