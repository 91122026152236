.container {
    width: 35%;
}
.main {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.submit {
    height: 50px !important;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .container {
        width: 90%;
    }
}