.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
    padding-right: 1.5rem;
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
}
.block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.line {
    width: 100%;
    display: flex;
    gap: 2rem;
}

@media screen and (max-width: 1024px) {
    .line {
        flex-direction: column;
    }
}