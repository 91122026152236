.text {
    padding: 10px 0px;
}
.footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0 10px;
}

@media screen and (max-width: 468px) {
    .text {
        font-size: 12px;
    }
}