.main {
    background-color: #1A1A1A;
    box-shadow: 0px 0px 10px -3px rgba(255, 85, 0, .3);
    color: white;
    padding: 25px;
    box-sizing: border-box;
    width: 50%;
    max-height: 95vh;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header button {
    color: white !important;
    background-color: transparent !important;
}
.body {
    margin-top: 20px;
    max-height: calc(95vh - 90px);
    overflow-y: auto;
}