.main {
    width: 100%;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.addBut {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px !important;
}
.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addFaq {
    width: 100% !important;
    height: 50px !important;
    font-size: 16px !important;
}
.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.buttonsContainer button {
    width: 100% !important;
    border: 1px solid transparent;
}
.secondaryButt {
    background-color: transparent !important;
    border: 1px solid #ff5500 !important;
}