.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: .7rem 1rem;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
}
.default {
    background-color:rgba(255, 85, 0, .2);
}
.green {
    background-color: rgba(0, 128, 0, .2)
}
.yellow {
    background-color: rgba(255, 165, 0, .2)
}
.main p {
    font-size: 24px;
    font-weight: bold;
}
.main h5 {
    font-size: 16px;
}