.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: 'helvetica-ce-bold';
}
.body {
    width: 100%;
    display: flex;
    gap: 24px;
}
.content {
    width: 100%;
    /* box-shadow: -2px -2px 10px -3px #FF5500; */
    /* padding: 25px; */
    box-sizing: border-box;
    color: white;
}