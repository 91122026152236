.main {
    width: 100%;
}
.main label {
    font-size: 12px;
    opacity: .45;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: block;
    margin-bottom: 4px;
}
.editor {
    background-color: transparent !important;
    /* font-family: 'helvetica-ce-bold' !important; */
    border: 1px solid rgba(255, 85, 0, .3) !important;
    border-radius: 0 !important;
}
.editor:hover {
    border: 1px solid rgba(255, 85, 0, .6) !important
}
.editor:active, .editor:focus { 
    border: 2px solid rgba(255, 85, 0, 1) !important
}
.error {
    color: #d32f2f;
    margin-top: 10px;
    display: block;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}