.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    padding: 3rem 0;
}
.main svg {
    font-size: 54px;
    color: gray;
}
.main p {
    margin-top: 15px;
    color: gray;
}