.table {
    background-color: transparent !important;
    max-height: calc(100vh - 245px);
}
.table tr th {
    color: white !important;
    border-bottom: 1px solid rgba(255, 85, 0, .1) !important;
    font-weight: bold !important;
    font-size: 18px !important;
}
.table a {
    color: white;
}
.table tr td {
    color: white !important;
    border-bottom: none !important;
}
.imgPreview {
    width: 100px;
    border: 1px solid rgba(255, 85, 0, .1);
    padding: 20px;
}
.pagination {
    color: white !important;
}
.pagination svg {
    color: white !important;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}