.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.bar {
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 8px;
    background-color: gray;
}
.mainBar {
    height: 12px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: green;
    z-index: 2;
}
.subBar {
    height: 12px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: orange;
}
.labels {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Helvetica";
    font-size: 18px;
    font-weight: bold;
}