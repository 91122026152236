.header {
    border-bottom: 1px solid rgba(255, 85, 0, .3);
    padding: 25px 0;
    padding-right: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.header h2 {
    font-family: "Roboto";
}
.left {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.left button {
    color: white !important;
}
.right {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.button {
    height: 100% !important;
    padding: 14px 24px !important;
}