body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1A1A1A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, p, span {
  margin-block-end: 0;
  margin-block-start: 0;
  font-family: "Roboto";
}

.w-md-editor {
  box-shadow: none !important;
}
.w-md-editor-toolbar {
  border-bottom: none !important;
  background-color: transparent !important;
}

.w-md-editor-text {
  line-height: 18px !important;
}

@font-face{
  font-family:"helvetica-ce-bold";
  src:url("https://candyfonts.com/wp-data/2019/04/06/51707/HVB.ttf") format("woff"),
  url("https://candyfonts.com/wp-data/2019/04/06/51707/HVB.ttf") format("opentype"),
  url("https://candyfonts.com/wp-data/2019/04/06/51707/HVB.ttf") format("truetype");
}
